<template>
  <q-dialog
    ref="dialogRef"
    :maximized="createPopUp.$state.maximized"
    :position="createPopUp.$state.position"
    style="z-index: 9999999"
  >
    <div
      v-touch-pan.mouse.down="handleSwipe"
      class="mobil-popup"
      :style="{ top: positionTop + 'px' }"
      :class="{ 'return-to-top': positionTop === 0 }"
    >
      <span class="mobil-popup-pull"/>
      <component
        :is="createPopUp.$state.currentPopUp"
        @hide_popup="closePopUp"
      />
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";
import type QDialog from "quasar";

import { useDialogPluginComponent } from "quasar";

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const createPopUp = PopUpServices();

const positionTop = ref<number>(0);

const closePopUp = (state: "ok" | "ca") => {
  if (state === "ok") {
    onDialogOK();
  } else if (state === "ca") {
    onDialogCancel();
  } else {
    onDialogHide();
  }
};

const handleSwipe = ({ evt, ...newInfo }) => {
  positionTop.value = newInfo.distance.y;
  if (newInfo.isFinal) {
    if (positionTop.value >= 90) {
      dialogRef.value?.hide();
      createPopUp.$state.currentPopUp = "";
      positionTop.value = 0;
    } else {
      positionTop.value = 0;
    }
  }
};
onMounted(() => {
  createPopUp.$state.popUpRef = dialogRef.value as any;
});
</script>

<style lang="scss">
.mobil-popup {
  height: 100%;
  max-height: 100% !important;
  width: 100vw !important;
  max-width: 100vw !important;
  overflow: hidden;
  border-radius: 12px 12px 0px 0px !important;
  background: var(--white, #fff);
  // padding: 16px;
  display: grid;
  justify-items: center;
  position: relative;
  &.return-to-top {
    transition: top 0.2s ease;
  }

  &-pull {
    display: flex;
    width: 48px;
    height: 4px;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--slate-300);
  }
}
.q-dialog__inner--minimized {
  padding: 0;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .q-dialog {
    z-index: 9999999999;
  }
}
</style>
